<template>
  <v-app v-if="isMobile()" :style="$root.lang == 'ar' ? merkazi : poppins">
    <v-app-bar
      app
      color="rgb(238, 240, 248)"
      style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
    >
      <KTBrand></KTBrand>
      <span class="font-size-h4 text-primary mx-2" style="font-weight: 500">{{
        this.$root.title
      }}</span>
      <v-spacer />
      <v-btn icon @click="langsheet = !langsheet">
        <img
          class="h-20px w-20px rounded-sm"
          :src="languageFlag || getLanguageFlag"
          alt=""
        />
      </v-btn>
      <router-link to="/profile" v-slot="{ href, navigate, isActive }">
           <v-btn
                @click="navigate"
                :href="href"
                icon
                width="35"
                height="35"
                class="mx-0"
                :class="isActive ? 'white' : 'amber darken-1'"
              >
                <v-avatar
                  v-if="$user.getCurrentUser()"
                  size="35"
                >
                  <v-img
                    v-if="$user.getCurrentUser().avatar != null"
                    :src="
                      $root.apiURL +
                      'assets/' +
                      $user.getCurrentUser().avatar.private_hash
                    "
                  ></v-img>
                  <unicon
                    v-else
                    name="user-circle"
                    :fill="isActive ? '#FFB300' : '#FFFFFF'"
                    height="22px"
                    width="22px"
                  ></unicon>
                </v-avatar>
              </v-btn>
        </router-link>
      

      <!--end: Language bar -->
      <!--begin::User-->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="py-20" :style="'margin-top:' + systembarheight + 'px'">
      <!-- Provides the application the proper gutter -->
      <v-container
        fluid
        :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
      >
        <!-- If using vue-router -->
        <keep-alive include="dashboard, PatientDashboard, file, patient_info">
          <router-view></router-view>
        </keep-alive>
      </v-container>
      

    </v-main>

    <v-footer
      fixed
      padless
      :style="
        'background-color: white;padding-bottom: ' + tabspadding * 0 + 'px;'
      "
    >
      <Menu />
    </v-footer>
    <v-bottom-sheet v-model="langsheet">
      <v-card :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'">
        <v-toolbar flat dark color="white" class="text-primary py-2">
          <v-toolbar-title>
            {{ $t("TRANSLATOR.SELECT") }}
          </v-toolbar-title>
          <v-spacer />

          <v-btn icon dark color="primary" @click="langsheet = !langsheet">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <KTDropdownLanguage
          class="bg-white"
          v-on:language-changed="onLanguageChanged"
        ></KTDropdownLanguage>
      </v-card>
    </v-bottom-sheet>
  </v-app>

  <v-app
    v-else
    style="width: 100% !important"
    :style="$root.lang == 'ar' ? merkazi : ''"
  >
    <v-navigation-drawer
      permanent
      color="primary"
      app
      width="90px"
      :right="$root.lang == 'ar'"
    >
      <KTBrand></KTBrand>
      <KTAside></KTAside>
      <!--begin::Item-->
      <template v-slot:append>
        <router-link to="/profile" v-slot="{ href, navigate, isActive }">
          <v-tooltip
            :left="$root.lang == 'ar'"
            :right="$root.lang != 'ar'"
            color="white"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                @click="navigate"
                :href="href"
                fab
                block
                icon
                v-bind="attrs"
                v-on="on"
                tile
                height="70px"
                :class="isActive ? 'white' : 'amber darken-1'"
              >
                <v-avatar
                  v-if="$user.getCurrentUser()"
                  tile
                  size="70"
                  width="90"
                >
                  <v-img
                    v-if="$user.getCurrentUser().avatar != null"
                    :src="
                      $root.apiURL +
                      'assets/' +
                      $user.getCurrentUser().avatar.private_hash
                    "
                  ></v-img>
                  <unicon
                    v-else
                    name="user-circle"
                    :fill="isActive ? '#FFB300' : '#FFFFFF'"
                  ></unicon>
                </v-avatar>
              </v-btn>
            </template>
            <span style="color: #0971ce">{{
              $user.getCurrentUser().first_name +
              " " +
              $user.getCurrentUser().last_name
            }}</span>
          </v-tooltip>
        </router-link>
      </template>

      <!--end::Item-->
    </v-navigation-drawer>
    <v-slide-x-transition>
      <v-navigation-drawer
        :right="$root.lang == 'ar'"
        width="300px"
        :style="$root.lang == 'ar' ? 'margin-right:90px' : 'margin-left:90px'"
        permanent
        app
        v-show="$root.admin.openside"
      >
        <RoleList v-if="$root.currentpage == 'users'" />
        <SettingsList v-if="$root.currentpage == 'AppSettings'" />
        <Conversations v-if="$root.currentpage == 'chat'" />
      </v-navigation-drawer>
    </v-slide-x-transition>
    <v-app-bar
      :style="
        $root.lang == 'ar'
          ? 'right:' + $root.admin.extramargin
          : 'left:' + $root.admin.extramargin
      "
      color="white"
      flat
      app
      height="80px"
      class="px-3"
    >
    <template v-if="$root.currentpage != 'chat'">
      <div class="font-size-h3 text-primary mx-1 mb-0">
        {{ this.$root.title }}
      </div>
      <v-spacer />
      <KTTopbar></KTTopbar>
    </template>
    <template v-else>
      <v-list-item v-if="chatuser">
            <v-list-item-avatar color="#f2f7fd" size="40">
              <v-avatar
                v-if="chatuser.remote != null && chatuser.remote.avatar"
                color="#f2f7fd"
                size="40"
              >
                <v-img
                  :src="
                    $root.apiURL + 'assets/' + chatuser.remote.avatar.private_hash
                  "
                ></v-img>
              </v-avatar>
              <v-avatar v-else color="#f2f7fd" size="40">
                <div
                  style="text-transform: uppercase; width: 100%"
                  class="primary--text font-size-h1"
                >
                  <span v-if="chatuser.remote != null && chatuser.remote.first_name">{{
                    chatuser.remote.first_name.charAt(0)
                  }}</span>
                  <span v-else>D</span>
                </div>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="chatuser.remote"
                  >{{ chatuser.remote.first_name }}
                  {{ chatuser.remote.last_name }}</span
                >
                <span v-else>{{ chatuser.title }}</span></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
    </template>
    </v-app-bar>
    <v-main
      :style="
        $root.lang == 'ar'
          ? 'padding-right:' + $root.admin.extramargin
          : 'padding-left:' + $root.admin.extramargin
      "
    >
      <v-container fluid>
        <transition name="fade-in-up">
          <router-view />
        </transition>
      </v-container>
    </v-main>
    <!--end::Content-->
    <!--begin::Footer-->
    <v-footer
    v-if="$root.currentpage != 'chat'"
      :style="
        $root.lang == 'ar'
          ? 'right:' + $root.admin.extramargin
          : 'left:' + $root.admin.extramargin
      "
      app
      inset
    >
      <div
        class="d-flex flex-row align-items-center justify-content-between"
        style="width: 100%"
      >
        <!--begin::Copyright-->
        <div class="">
          <span class="text-muted font-weight-bold mr-2">2021©</span>
          <a target="_blank" class="text-dark-75 text-hover-primary"
            >Doctory Care</a
          >
        </div>
        <!--end::Copyright-->
        <v-spacer />
        <!--begin::Nav-->
        <div
          style="width: fit-content; display: flex; width: fit-content"
          class=""
        >
          <a target="_blank" class="nav-link pr-3 pl-0">About</a>
          <a target="_blank" class="nav-link px-3">Team</a>
          <a target="_blank" class="nav-link pl-3 pr-0">Contact</a>
        </div>
        <!--end::Nav-->
      </div>
    </v-footer>
    <!--end::Footer-->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { Plugins, StatusBarStyle } from "@capacitor/core";
const { PushNotifications, StatusBar } = Plugins;
//import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Layout",
  components: {
    KTAside: () => import("@/view/layout/aside/Aside.vue"),
    KTTopbar: () => import("@/view/layout/header/Topbar"),
    KTBrand: () => import("@/view/layout/brand/Brand"),
    KTDropdownLanguage: () =>
      import("@/view/layout/extras/dropdown/DropdownLanguage.vue"),
    Menu: () => import("@/view/layout/footer/Menu"),
    RoleList: () => import("@/view/pages/admin/users/RoleList.vue"),
    SettingsList: () => import("@/view/pages/admin/users/SettingsList.vue"),
    Conversations: () => import("@/view/Chat/Conversations.vue")
  },
  data() {
    return {
      drawer: false,
      languageFlag: "",
      didcheckfmc: false,
      languages: i18nService.languages,
      avatarAvail: false,
      avatar: null,
      showuser: false,
      notificationsSupported: false,
      notificationsEnabled: false,
      subscription: null,
      deviceInfo: "",
      screenratio: 0.0,
      systembarheight: 0,
      tabspadding: 0,
      langsheet: true,
      isiOS: false,
      poppins: "font-family: 'Poppins' !important;",
      merkazi: "font-family: 'Markazi Text' !important;",
      //---- admin
      current: "",
      //---- chat user
      chatuser:null,
    };
  },
  watch: {
    $route(to) {
      console.log("page name", to.name);
      this.$root.currentpage = to.name;
      if (to.name == "users" || to.name == "AppSettings") {
        this.$root.admin.extramargin = "390px";
        this.$root.admin.openside = true;
      } else {
        this.$root.admin.extramargin = "90px";
        this.$root.admin.openside = false;
      }
    },
  },
  async beforeCreate() {
    /*if(this.$user.getCurrentUser() == null)
    {
      await this.$user
      .fetchCurrentUser()
      .then((d) => {
        console.log("loaded current user and closing loader", d);
        this.$root.loader = false;
      })
      .then(() => {
        console.log("coming from", this.$route)
        //if(this.$route.)
        this.$router.push({ name: "dashboard" });
      })
      .catch((e) => {
        console.log("verify error", e);
        this.$store.dispatch(LOGOUT).finally(() => {
          this.$root.loader = false;
        });
      })
      .finally(() => {
        this.$root.loader = false;
      });
    }
    else{
      console.log("user already there and it is", this.$user.getCurrentUser())
      
    }*/
    this.$root.loader = false;
  },
  beforeMount() {
    this.getDeviceInfo();
    // show page loading
    //this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
  },
  created() {
   
  },
  beforeDestroy() {
    console.log("removing event listeners");
    if (this.deviceInfo.platform != "web") {
      PushNotifications.removeAllListeners();
    }
    this.$cap.removeAllListeners();
  },
  mounted() {
    // check if current user is authenticated

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
    console.log("current user is", this.$user.getCurrentUser());
    //console.log(JSON.parse(localStorage.userInfo).avatar )
    if (JSON.parse(localStorage.userInfo).avatar != null) {
      this.avatarAvail = true;
      this.picture();
    }

    this.toggleSubscription();
    this.setBackListner();

    this.$util.EventBus.$on("chatRoomChange", () => {
      this.chatuser = this.$chat.getActiveRoom();
    })
    // Simulate the delay page loading
    /*setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);*/
  },
  methods: {
    setBackListner() {
      this.$cap.addListener("backButton", (data) => {
        console.log("data from back btn is", data);
        console.log("current route is", this.$route.name);
        if (this.$route.name != "dashboard") this.$router.go(-1);
      });
    },
    getcachedpages() {
      if (JSON.parse(localStorage.userInfo).role == 4) {
        return "dashboard, PatientDashboard, patient_info";
      }
    },
    async getDeviceInfo() {
      const { Device } = Plugins;

      this.deviceInfo = await Device.getInfo();

      localStorage.setItem("device", this.deviceInfo.operatingSystem);

      this.setStatusbar();
    },
    setStatusbar() {
      if (this.deviceInfo.platform != "web") {
        StatusBar.setStyle({ style: StatusBarStyle.Light });
        StatusBar.setBackgroundColor({ color: "#eef0f8" });
      }
    },
    findSubscription() {
      //console.log('get active service worker registration');
      return navigator.serviceWorker.ready.then((swreg) => {
        //console.log('haal active subscription op');
        this.serviceWorkerRegistation = swreg;
        return this.getSubscription(this.serviceWorkerRegistation);
      });
    },
    getSubscription(swreg) {
      //console.log('ask for available subscription');
      //console.log(swreg);
      return swreg.pushManager.getSubscription();
    },
    checkdevicetoken(token) {
      if (window.localStorage.fmc_token) {
        var key = this.$ls.encrypt(
          JSON.parse(localStorage.userInfo).id,
          token.value
        );
        console.log("key", key);
        if (key == window.localStorage.fmc_token) {
          return 2;
        } else {
          return 3;
        }
      }
      return 1;
    },
    registerMobileNotification() {
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      PushNotifications.addListener("registration", (token) => {
        // get new (or existing user) from backend
        var t = this.checkdevicetoken(token);
        if (t == 1) {
          var sendSub = {
            platform: this.deviceInfo.platform,
            key: token.value,
            device_info: this.deviceInfo,
            status: "published",
          };
          window.localStorage.setItem(
            "fmc_token",
            this.$ls.encrypt(JSON.parse(localStorage.userInfo).id, token.value)
          );
          ApiService.post("items/notification_center", sendSub);
        } else if (t == 3) {
          this.resendToken(token);
        }
      });

      PushNotifications.addListener("registrationError", (error) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push received: " + JSON.stringify(notification));
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log("Push action performed: " + JSON.stringify(notification));
        }
      );
    },
    async resendToken(token) {
      await ApiService.get(
        "items/notification_center?filter[key]=" + token.value
      )
        .then(({ data }) => {
          var sendSub = {
            platform: this.deviceInfo.platform,
            device_info: this.deviceInfo,
            owner: JSON.parse(localStorage.userInfo).id,
          };
          window.localStorage.fmc_token = this.$ls.encrypt(
            JSON.parse(localStorage.userInfo).id,
            token.value
          );
          ApiService.update(
            "items/notification_center",
            data.data[0].id,
            sendSub
          );
        })
        .catch((e) => {
          console.log("error in here", e);
        });
    },
    async toggleSubscription() {
      const { Device } = Plugins;

      this.deviceInfo = await Device.getInfo();
      //console.log("checking notification and platform is", this.deviceInfo);
      /*console.log(
        "checking notification and platform is",
        this.deviceInfo.operatingSystem
      );*/
      if (
        (this.deviceInfo.operatingSystem == "ios" ||
          this.deviceInfo.operatingSystem == "android") &&
        this.deviceInfo.platform != "web"
      ) {
        this.registerMobileNotification();
      } else {
         if ("Notification" in window && "serviceWorker" in navigator) {
            this.notificationsSupported = true;
          }
        await this.findSubscription().then((sub) => {
          if (sub === null) {
            //console.log("no active subscription found on the client", sub);
            this.notificationsEnabled = false;
          } else {
            //console.log("Active subscription found", sub);
            // retrieve user info from API
            this.notificationsEnabled = true;
            this.subscription = sub;
          }
        });

        //console.log("in toggleSubscription");
        //console.log("are Notificaitons supported ", this.notificationsSupported);
        //console.log("are Notificaitons enabled ", this.notificationsEnabled);
        if (this.notificationsSupported) {
          // Find out if we need to create a subscription or delete it
          if (!this.notificationsEnabled) {
            // Ask permission and when granted, create new subscription
            Notification.requestPermission().then((result) => {
              // if granted, create new subscription
              if (result === "granted") {
                this.createSubscription().then((sub) => {
                  //console.log("subscription created on the client", sub);
                  this.subscription = sub;
                  this.notificationsEnabled = true;
                  // get new (or existing user) from backend
                  var sendSub = {
                    platform: this.deviceInfo.platform,
                    sub_details: sub,
                    status: "published",
                  };
                  return ApiService.post("items/notification_center", sendSub);
                });
              }
            });
          }
        }
      }
    },
    createSubscription() {
      //console.log("ask for active service worker registration");
      if (this.serviceWorkerRegistation === null) {
        return navigator.serviceWorker.ready // returns a Promise, the active SW registration
          .then((swreg) => {
            this.serviceWorkerRegistation = swreg;
            return this.subscribe(this.serviceWorkerRegistation);
          });
      } else {
        return this.subscribe(this.serviceWorkerRegistation);
      }
    },
    subscribe(swreg) {
      //console.log("create new subscription for this browser on this device");
      // create new subscription for this browser on this device
      const vapidPublicKey =
        "BP43iWV-06bNKfhMMs255nelj08Y2oRgGRkUCOo3LgYtDriLqj1pDa8GSXgF9Gyr8KYAQXR-a1DboyQ7fHN2r5w";
      const convertedVapidPublicKey =
        this.urlBase64ToUint8Array(vapidPublicKey);
      //console.log("converted vpid is", convertedVapidPublicKey)
      // return the subscription promise, we chain another then where we can send it to the server
      return swreg.pushManager.subscribe({
        userVisibleOnly: true,
        // This is for security. On the backend, we need to do something with the VAPID_PRIVATE_KEY
        // that you can find in .env to make this work in the end
        applicationServerKey: convertedVapidPublicKey,
      });
    },
    isMobile() {
      this.$root.mobile = this.$vuetify.breakpoint.mobile;
      return this.$root.mobile;
    },
    async picture() {
      //console.log(this.userInfo);
      if (JSON.parse(localStorage.userInfo).avatar) {
        await ApiService.get(
          "files",
          JSON.parse(localStorage.userInfo).avatar + "?fields=data"
        )
          .then(({ data }) => {
            console.log(data.data);
            this.avatar = data.data.data.thumbnails[0].url;
          })
          .catch((e) => {
            console.log("error in here", e);
          });
      } else {
        return null;
      }
    },
    getInitials() {
      return (
        JSON.parse(localStorage.userInfo).first_name.charAt(0) +
        JSON.parse(localStorage.userInfo).last_name.charAt(0)
      );
    },
    onLanguageChanged() {
      this.langsheet = !this.langsheet;
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        //eslint-disable-next-line
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      let outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>

<style scoped></style>
<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.v-data-table tr td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1rem !important;
  text-transform: uppercase;
}

.v-text-field--outlined fieldset {
  border-width: 2px !important;
}
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: rgba(0, 0, 0, 0.15);
}

.dot-green {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(27, 197, 189, 1);
  transform: scale(1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(27, 197, 189, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(27, 197, 189, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(27, 197, 189, 0);
  }
}

.dot-red {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(246, 78, 96, 1);
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(246, 78, 96, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(246, 78, 96, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(246, 78, 96, 0);
  }
}

.dot-yellow {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(255, 168, 0, 1);
  transform: scale(1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 168, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 168, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 168, 0, 0);
  }
}

.dot-blue {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;

  box-shadow: 0 0 0 0 rgba(9, 113, 206, 1);
  transform: scale(1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(9, 113, 206, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(9, 113, 206, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(9, 113, 206, 0);
  }
}
</style>
