var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMobile())?_c('v-app',{style:(_vm.$root.lang == 'ar' ? _vm.merkazi : _vm.poppins)},[_c('v-app-bar',{staticStyle:{"box-shadow":"rgb(0 0 0 / 8%) 0px 4px 12px"},attrs:{"app":"","color":"rgb(238, 240, 248)"}},[_c('KTBrand'),_c('span',{staticClass:"font-size-h4 text-primary mx-2",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(this.$root.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.langsheet = !_vm.langsheet}}},[_c('img',{staticClass:"h-20px w-20px rounded-sm",attrs:{"src":_vm.languageFlag || _vm.getLanguageFlag,"alt":""}})]),_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('v-btn',{staticClass:"mx-0",class:isActive ? 'white' : 'amber darken-1',attrs:{"href":href,"icon":"","width":"35","height":"35"},on:{"click":navigate}},[(_vm.$user.getCurrentUser())?_c('v-avatar',{attrs:{"size":"35"}},[(_vm.$user.getCurrentUser().avatar != null)?_c('v-img',{attrs:{"src":_vm.$root.apiURL +
                    'assets/' +
                    _vm.$user.getCurrentUser().avatar.private_hash}}):_c('unicon',{attrs:{"name":"user-circle","fill":isActive ? '#FFB300' : '#FFFFFF',"height":"22px","width":"22px"}})],1):_vm._e()],1)]}}],null,false,3675458354)})],1),_c('v-main',{staticClass:"py-20",style:('margin-top:' + _vm.systembarheight + 'px')},[_c('v-container',{style:('padding-bottom: ' + this.$root.tabspadding + 'px;'),attrs:{"fluid":""}},[_c('keep-alive',{attrs:{"include":"dashboard, PatientDashboard, file, patient_info"}},[_c('router-view')],1)],1)],1),_c('v-footer',{style:('background-color: white;padding-bottom: ' + _vm.tabspadding * 0 + 'px;'),attrs:{"fixed":"","padless":""}},[_c('Menu')],1),_c('v-bottom-sheet',{model:{value:(_vm.langsheet),callback:function ($$v) {_vm.langsheet=$$v},expression:"langsheet"}},[_c('v-card',{style:('padding-bottom: ' + this.$root.tabspadding + 'px;')},[_c('v-toolbar',{staticClass:"text-primary py-2",attrs:{"flat":"","dark":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("TRANSLATOR.SELECT"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":"","color":"primary"},on:{"click":function($event){_vm.langsheet = !_vm.langsheet}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('KTDropdownLanguage',{staticClass:"bg-white",on:{"language-changed":_vm.onLanguageChanged}})],1)],1)],1):_c('v-app',{staticStyle:{"width":"100% !important"},style:(_vm.$root.lang == 'ar' ? _vm.merkazi : '')},[_c('v-navigation-drawer',{attrs:{"permanent":"","color":"primary","app":"","width":"90px","right":_vm.$root.lang == 'ar'},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
return [_c('v-tooltip',{attrs:{"left":_vm.$root.lang == 'ar',"right":_vm.$root.lang != 'ar',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:isActive ? 'white' : 'amber darken-1',attrs:{"elevation":"0","href":href,"fab":"","block":"","icon":"","tile":"","height":"70px"},on:{"click":navigate}},'v-btn',attrs,false),on),[(_vm.$user.getCurrentUser())?_c('v-avatar',{attrs:{"tile":"","size":"70","width":"90"}},[(_vm.$user.getCurrentUser().avatar != null)?_c('v-img',{attrs:{"src":_vm.$root.apiURL +
                    'assets/' +
                    _vm.$user.getCurrentUser().avatar.private_hash}}):_c('unicon',{attrs:{"name":"user-circle","fill":isActive ? '#FFB300' : '#FFFFFF'}})],1):_vm._e()],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"#0971ce"}},[_vm._v(_vm._s(_vm.$user.getCurrentUser().first_name + " " + _vm.$user.getCurrentUser().last_name))])])]}}])})]},proxy:true}])},[_c('KTBrand'),_c('KTAside')],1),_c('v-slide-x-transition',[_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$root.admin.openside),expression:"$root.admin.openside"}],style:(_vm.$root.lang == 'ar' ? 'margin-right:90px' : 'margin-left:90px'),attrs:{"right":_vm.$root.lang == 'ar',"width":"300px","permanent":"","app":""}},[(_vm.$root.currentpage == 'users')?_c('RoleList'):_vm._e(),(_vm.$root.currentpage == 'AppSettings')?_c('SettingsList'):_vm._e(),(_vm.$root.currentpage == 'chat')?_c('Conversations'):_vm._e()],1)],1),_c('v-app-bar',{staticClass:"px-3",style:(_vm.$root.lang == 'ar'
        ? 'right:' + _vm.$root.admin.extramargin
        : 'left:' + _vm.$root.admin.extramargin),attrs:{"color":"white","flat":"","app":"","height":"80px"}},[(_vm.$root.currentpage != 'chat')?[_c('div',{staticClass:"font-size-h3 text-primary mx-1 mb-0"},[_vm._v(" "+_vm._s(this.$root.title)+" ")]),_c('v-spacer'),_c('KTTopbar')]:[(_vm.chatuser)?_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"#f2f7fd","size":"40"}},[(_vm.chatuser.remote != null && _vm.chatuser.remote.avatar)?_c('v-avatar',{attrs:{"color":"#f2f7fd","size":"40"}},[_c('v-img',{attrs:{"src":_vm.$root.apiURL + 'assets/' + _vm.chatuser.remote.avatar.private_hash}})],1):_c('v-avatar',{attrs:{"color":"#f2f7fd","size":"40"}},[_c('div',{staticClass:"primary--text font-size-h1",staticStyle:{"text-transform":"uppercase","width":"100%"}},[(_vm.chatuser.remote != null && _vm.chatuser.remote.first_name)?_c('span',[_vm._v(_vm._s(_vm.chatuser.remote.first_name.charAt(0)))]):_c('span',[_vm._v("D")])])])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.chatuser.remote)?_c('span',[_vm._v(_vm._s(_vm.chatuser.remote.first_name)+" "+_vm._s(_vm.chatuser.remote.last_name))]):_c('span',[_vm._v(_vm._s(_vm.chatuser.title))])])],1)],1):_vm._e()]],2),_c('v-main',{style:(_vm.$root.lang == 'ar'
        ? 'padding-right:' + _vm.$root.admin.extramargin
        : 'padding-left:' + _vm.$root.admin.extramargin)},[_c('v-container',{attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)],1),(_vm.$root.currentpage != 'chat')?_c('v-footer',{style:(_vm.$root.lang == 'ar'
        ? 'right:' + _vm.$root.admin.extramargin
        : 'left:' + _vm.$root.admin.extramargin),attrs:{"app":"","inset":""}},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-between",staticStyle:{"width":"100%"}},[_c('div',{},[_c('span',{staticClass:"text-muted font-weight-bold mr-2"},[_vm._v("2021©")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"target":"_blank"}},[_vm._v("Doctory Care")])]),_c('v-spacer'),_c('div',{staticStyle:{"width":"fit-content","display":"flex"}},[_c('a',{staticClass:"nav-link pr-3 pl-0",attrs:{"target":"_blank"}},[_vm._v("About")]),_c('a',{staticClass:"nav-link px-3",attrs:{"target":"_blank"}},[_vm._v("Team")]),_c('a',{staticClass:"nav-link pl-3 pr-0",attrs:{"target":"_blank"}},[_vm._v("Contact")])])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }